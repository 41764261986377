<template>
	<div class="qr-entries-table pb-3">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
						</div>
					</template>
				</v-data-footer>
			</template>
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- Фильтр Адреса -->
					<td>
						<v-autocomplete
							v-model="filter.house_ids"
							placeholder="Адрес"
							class="text-caption border-all"
							solo
							item-text="apartment_address"
							item-value="id"
							:return-object="false"
							:items="houses"
							:filter="addressFilter"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:prepend-item>
									<v-checkbox
										class="ft-14 ma-0 ml-4 mb-1"
										v-model="selectAllHouses"
										hide-details
										
									>
										<template v-slot:label>
											<span class="ml-5" style="font-size: 0.8125rem; font-weight: 500; line-height: 1rem;">Выбрать все</span>
										</template>
									</v-checkbox>
									<hr>
								</template>
						</v-autocomplete>
					</td>
					<td>
						<v-select
							v-model="filter.status_id"
							class="border-all"
							placeholder="Статус"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Успешно', value: '1' },
								{ text: 'Не успешно', value: '2' },
							]"
							@change="updateQuery"
						>
						</v-select>
					</td>
					<td></td>
					<td>
						<v-select
							v-model="filter.device_id"
							class="border-all"
							placeholder="Статус"
							solo
							dense
							item-text="name"
							item-value="id"
							flat
							hide-details
							:items="devices"
							@change="updateQuery"
						>
						</v-select>
					</td>
				</tr>
			</template>
			<template v-slot:[`item.houses`]="{ item }">
				<td>{{ item.house.apartment_address }}</td>
			</template>
			<template v-slot:[`item.user`]="{ item }">
				<td>
					<span>{{ item.user | fullname }}</span>
					<br>
					<span>{{ item.user.phone }}</span>
				</td>
			</template>
			<template v-slot:[`item.time`]="{ item }">
				{{ item.time | timestamp() }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";

import QrTableGroupsModal from "@/components/QrNextGroupsModal";

export default {
	name: "QrNextLogs",

	components: {
		QrTableGroupsModal,
	},

	data() {
		return {
			toEdit: null,
			firstFetch: false,
			items: [],
			devices: [],
			selectAllHouses: null,
			filter: {
				house_ids: null,
				status_id: null,
				device_id: null,
			},
			filtersQuery: {
				"house_ids": "house_id",
				"status_id": "status_id",
				"device_id": "device_id"
			},
			headers: [
				{ text: "Адреса", value: "houses", sortable: false },
				{ text: "Статус", value: "status.name", sortable: false },
				{ text: "Ошибка", value: "error.name", sortable: false },
				{ text: "Устройство", value: "device.name", sortable: false },
				{ text: "Пользователь", value: "user", sortable: false },
				{ text: "Время", value: "time", sortable: false },
				
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageStop: 0,
				pageCount: 0,
				itemsLength: 0,
			},
			loading: true,
			showModalCreate: false,
			showModalEdit: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			role: "auth/GET_USER_ROLE_VUEX",
			houses: "ksk/GET_NEWQR_EXIST",
			kskId: "auth/GET_KSK_ID",
		}),
	},

	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					const query = { ...this.$route.query, page: this.options.page };
					if(query['house_id']) {
						this.selectAllHouses = false
					}
					this.$router.replace({ query });
					this.loadData(query);
				}
			},
			deep: true,
		},
		'selectAllHouses' () {
			if(this.selectAllHouses) {
				this.filter.house_ids = null
				const query = { ...this.$route.query, page: this.options.page };
				delete query['house_id']
				this.$router.replace({ query })
				this.loadData()
			}
		}
	},

	methods: {
		timestampToDdmmyyyy,

		currentHouses(item) {
			if(item.length > 1) {
				const result = this.houses.find(({ id }) => id === item);
				return result?.apartment_address
			}
		},

		// ? Методы таблицы
		handleRowClick(e) {
			console.log('e', e)
		},

		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params) {
			this.loading = true;
			params.company_id = this.kskId
			return this.$api.ksk
				.load_logs(params)
				.then(res => {
					this.items = res.records;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
					this.firstFetch = true
				});
		},

		async loadDevices(params) {
			this.loading = true;
			return this.$api.ksk
				.load_devices(params)
				.then(res => {
					this.devices = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch("ksk/load_houses_all");
		this.$store.dispatch("ksk/load_employee");
	},

	async mounted() {
		try {
			const query = { ...this.$route.query, page: this.options.page };
			const houses_ids = this.houses.map(item => item.id);
			if(houses_ids.length) {
				const params = { house_ids: houses_ids};
				this.loadDevices(params)
			}
			await this.loadData(query);
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.whitelist-table {
	height: 100vh;
}
</style>
